module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-FX2JWSS3TY","cookieName":"gatsby-gdpr","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"G-FX2JWSS3TY","cookieName":"gatsby-gdpr","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://ecoconseilhabitat42.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Eco Conseil Habitat","short_name":"ECH","description":"Solutions clés en main pour salle de bains, plomberie et chauffage.","lang":"fr","display":"standalone","start_url":"/","background_color":"#ffffff","theme_color":"#35983C","icon":"src/images/logos/ech-eco-conseil-habitat-icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a90deeffe4755a868d8ad07917b23545"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
